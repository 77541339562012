import { navigate } from 'gatsby';
import { useEffect } from 'react';
import { APP_ROUTES } from '../routes';

export const useGoHome = () => {
  /**
   * Hooks
   */
  useEffect(() => {
    navigate(APP_ROUTES.HOME);
  }, []);
};
