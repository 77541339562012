import { useGoHome } from '../../hooks/use-go-home';

export const RedirectToHome = () => {
  /**
   * Hooks
   */
  useGoHome();

  /**
   * DOM
   */
  return null;
};
