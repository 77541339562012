export enum APP_ROUTES {
  HOME = `/`,
  HOME_CONTACT_US = '/#get-started',

  ABOUT_US = `/about-us`,

  INSIGHTS = `/insights`,

  SOLUTIONS = '/solutions',
  SOLUTIONS_CUSTODY = '/solutions/custody',
  SOLUTIONS_TRADING = '/solutions/trading',
  SOLUTIONS_WHITE_LABEL = '/solutions/white-label',

  DOWNLOAD_APP = '/download-app',
  TERMS_OF_USE = `/legal/?topic=terms-of-use`,

  CONTACT_US = '/contact-us',
  /**
   * @deprecated routes - which should redirect to home page
   */
  WAITLIST = `/waitlist`,
  RATES = '/rates',
  DEPOSIT_REWARD = `/deposit-reward`,
}

export const EXTERNAL_ROUTES = {
  APP_URL: process.env.REACT_APP_BASE_URL,
  API_URL: process.env.REACT_APP_API_URL,
};
